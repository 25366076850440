import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog';
import { CommonModule } from '@angular/common';
import {
    Component,
    computed,
    effect,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateModule } from '@ngx-translate/core';
import { isEqual } from 'lodash';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import {
    BehaviorSubject,
    distinctUntilChanged,
    Subject,
    switchMap,
} from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { UisrTools } from 'src/app/core/utils/uisr-tools';
import { PAGINATION_CONFIG } from 'src/app/features/law-firm/constants/pagination.constants';
import { DossierFilter } from 'src/app/features/law-firm/interfaces/dossier-filter.interface';
import { StorageObjectFilter } from 'src/app/features/law-firm/interfaces/storage-object-filter.interface';
import { StorageObject } from 'src/app/features/law-firm/interfaces/storage-object.interface';
import { DossierIconStatusPipe } from 'src/app/features/law-firm/pipes/dossier-icon-status.pipe';
import { DossierStatusIconPipe } from 'src/app/features/law-firm/pipes/dossier-status-icon.pipe';
import { COLLECTION_TYPES } from 'src/app/features/search/constants/collections.constants';
import { CollectionLite } from 'src/app/features/search/interfaces/collections.interfaces';
import { DossierDTO } from '../../../features/law-firm/interfaces/dossier.interfaces';
import { MenuModule } from '../../modules/menu.module';
import { AppPaginationModule } from '../../modules/pagination.module';
import { loadingState } from '../../operators/loading-state.operator';
import { CollectionsService } from '../../services/collections.service';
import { UisrApiServiceV2 } from '../../services/uisr-api.service-v2';
import { DossierFolderDataComponent } from './dossier-folder-data/dossier-folder-data.component';

@UntilDestroy()
@Component({
    selector: 'app-dossier-folder-selector',
    standalone: true,
    imports: [
        CommonModule,
        TranslateModule,
        AppPaginationModule,
        NgxTippyModule,
        DossierIconStatusPipe,
        DossierStatusIconPipe,
        InfiniteScrollModule,
        ReactiveFormsModule,
        MenuModule,
        DossierFolderDataComponent,
    ],
    templateUrl: './dossier-folder-selector.component.html',
})
export class DossierFolderSelectorComponent implements OnInit {
    private readonly _apiService = inject(UisrApiServiceV2);

    sizeLimit?: number;
    data? = inject(DIALOG_DATA, { optional: true });
    dialogRef? = inject(DialogRef, { optional: true });
    loading = new BehaviorSubject(false);
    scrolling = new BehaviorSubject(false);
    loadingFolder = new BehaviorSubject(false);
    loadingCollectionsData = new BehaviorSubject(false);
    selection: Set<StorageObject | DossierDTO> = new Set();
    existingIds: any[] = [];
    existingSearchObjectIds: any[] = [];
    dossiers: DossierDTO[] = [];
    dossierFilterSubject = new Subject<DossierFilter>();
    dossierFilter: DossierFilter = {};
    dossierPagination = structuredClone(PAGINATION_CONFIG);
    selectedFolder?: StorageObject;
    selectedDossier?: DossierDTO;
    searchControl: FormControl<null | string> = new FormControl(null);

    storageObjects: StorageObject[] = []; // Listado de documentos o carpetas de una asunto


    @Input() prompt?: string;
    @Input() extensions?: string[] = [];
    @Input() embedded = false;
    @Input() multiple = false;

    @Output() selectionChange = new EventEmitter<Set<StorageObject | DossierDTO >>();

    constructor() {
        this.dossierPagination.id = 'dossier-pagination';
        if (this.data) {
            this.prompt = this.data.prompt;
            this.extensions = this.data.extensions || [];
            this.existingIds = this.data.existing || [];
            this.sizeLimit = this.data.sizeLimit || undefined;
        }
    }

    ngOnInit() {
        this.subscribeToDossierFilter();
        this.applyDossierFilter({ pageNumber: 1, pageSize: 10 });
    }

    /** Permite hacer modificaciones al filtro de asuntos antes de realizar una búsqueda, elimina los campos vacíos */
    applyDossierFilter(newFilter: DossierFilter) {
        this.dossierFilter = { ...this.dossierFilter, ...newFilter };

        this.dossierFilter = UisrTools.removeEmptyProperties(
            this.dossierFilter
        );

        this.dossierFilterSubject.next(this.dossierFilter);
    }

    /** Se ejecuta cuando el usuario confirma la selección, emite los ids de los documentos seleccionados */
    onSubmit() {
        // Los documentos seleccionados desde un dossier se mandan completos junto al tipo dossierFile
        const folders: any[] = Array.from(this.selection).map((f) => ({
            ...f,
            type: 'dossierFile',
        }));

        this.dialogRef?.close(folders);
    }

    trackByFn(index: any, item: any) {
        return index;
    }

    /** Se suscribe al observable del filtro de asuntos para realizar una búsqueda en cuanto hayan cambios */
    subscribeToDossierFilter() {
        this.dossierFilterSubject
            .pipe(
                distinctUntilChanged(isEqual),
                switchMap((filter) => {
                    this.dossierPagination.currentPage = filter.pageNumber || 1;
                    this.dossierPagination.itemsPerPage = filter.pageSize || 10;

                    return this._apiService
                        .post(RESOURCES.dossierList, filter)
                        .pipe(loadingState(this.loading));
                }),
                untilDestroyed(this)
            )
            .subscribe({
                next: (res: APIResponse<DossierDTO[]>) => {
                    this.dossiers = res.data;
                    this.dossierPagination.totalItems = res.total || 0;
                },
            });
    }

    /** Limpia el input de búsqueda y realiza la búsqueda de asuntos con los filtros por defecto */
    clearSearch() {
        this.searchControl.setValue(null);
        this.onSearch();
    }

    /** Al realizar la búsqueda, si se ha seleccionado un asunto, buscar en storage objects, de lo contrario, filtrar los asuntos */
    onSearch() {
        if (this.selectedDossier) {
            if (this.searchControl.value) {
                this.onStorageObjectSearch();
            } else {
                this.getFolderData();
            }
        } else {
            this.applyDossierFilter({
                pageNumber: 1,
                searchValue: this.searchControl.value || undefined,
            });
        }
    }

    /** Realiza la búsqueda de storage object con el valor del input de búsqueda de carpetas y documentos */
    onStorageObjectSearch() {
        const filter: StorageObjectFilter = {};
        filter.dossierId = Number(this.selectedDossier?.idDossier);
        filter.searchValue = this.searchControl.value || undefined;
        if (!filter.dossierId) return;

        filter.fileExts = this.extensions;

        this._apiService
            .get(RESOURCES.storageObjects, filter)
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<any>) => {
                    this.storageObjects = res.data;

                    this.selectedFolder = undefined;
                },
            });
    }

    /** Al hacer click en una carpeta, se agrega al arreglo de carpetas seleccionadas */
    onStorageObjectClick(storageObject: StorageObject) {
        if (this.selection.has(storageObject)) {
            this.selection.delete(storageObject);
        } else if (this.multiple) {
            this.selection.add(storageObject);
        } else {
            this.selection.clear();
            this.selection.add(storageObject);
        }

        this.selectionChange.emit(this.selection);
    }

    /** Abre y cierra una carpeta y permite consultar sus sub-carpetas */
    toggleFolder(storageObject: StorageObject) {
        if (storageObject.storageObjects && storageObject.open) {
            storageObject.open = false;
        } else if (!storageObject.storageObjects) {
            storageObject.open = true;
            this.getFolderData(storageObject);
        } else {
            storageObject.open = true;
        }
    }

    /** Permite obtener los datos de una carpeta, obtiene sus documentos y sub-carpetas */
    getFolderData(folder?: StorageObject) {
        const filter: StorageObjectFilter = {};
        if (folder) {
            this.selectedFolder = folder;
            filter.dossierId = Number(this.selectedFolder.dossierId);
            filter.parentId = Number(this.selectedFolder.id);
        } else {
            filter.dossierId = Number(this.selectedDossier?.idDossier);
        }

        filter.fileExts = this.extensions;

        if (!filter.dossierId) return;

        this._apiService
            .get(RESOURCES.storageObjectDetails, filter)
            .pipe(loadingState(this.loadingFolder), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<any>) => {
                    if (!res.data.folders?.length && this.selectedDossier) {
                        // El asunto no tiene ninguna carpeta, se puede seleccionar el asunto directamente
                        this.selection.clear();
                        this.selection.add(this.selectedDossier);
                        this.selectionChange.emit(this.selection);
                    }
                    if (!folder) {
                        this.storageObjects = [...res.data.folders];
                    } else {
                        folder.storageObjects = [...res.data.folders];
                    }

                    this.selectedFolder = undefined;
                },
            });
    }

    /** Permite seleccionar un asunto y consultar sus documentos y carpetas. Solo se ejecuta si el asunto seleccionado es diferente al actual */
    selectDossier(dossier: DossierDTO) {
        if (this.selectedDossier?.idDossier != dossier.idDossier) {
            this.selectedDossier = dossier;
            this.getFolderData();
        }
    }

    /** Buscar la siguiente página de asuntos al hacer scroll */
    nextPage() {
        if (
            this.dossierPagination.totalItems &&
            this.dossierPagination.totalItems > this.dossiers.length
        ) {
            this.dossierFilter.pageNumber =
                this.dossierPagination.currentPage + 1;
            this._apiService
                .post(RESOURCES.dossierList, this.dossierFilter)
                .pipe(loadingState(this.scrolling), untilDestroyed(this))
                .subscribe({
                    next: (res: APIResponse<DossierDTO[]>) => {
                        this.dossiers.push(...res.data);
                        this.dossierPagination.currentPage =
                            this.dossierFilter.pageNumber || 1;
                        this.dossierPagination.itemsPerPage =
                            this.dossierFilter.pageSize || 10;
                        this.dossierPagination.totalItems = res.total || 0;
                    },
                });
        }
    }

    /** Obtiene el texto que se debe mostrar en el searchInput según la vista en la que está el usuario */
    getInputPlaceholder() {
        return this.selectedDossier
            ? 'Busca por nombre del documento o carpeta'
            : 'Busca por titulo, número u órgano del asunto';
    }

    /** Volver al listado principal ya sea de asuntos o de colecciones según selectedTab y reiniciar los listados de documentos */
    returnToList() {
        this.selectedDossier = undefined;
        this.storageObjects = [];
    }
}
