<div class="overflow-auto bg-pearl-100">
  <ng-container *ngFor="let storageObject of storageObjects; let i = index; trackBy:trackByFn">
    <!-- Carpeta o documento -->
    <div class="-mt-px first:mt-0">
      <div class="enabled:hover:bg-indigo-50 flex items-center gap-2 p-2 group border w-full border-transparent cursor-pointer"
        (click)="container.onStorageObjectClick(storageObject)"
        
        [ngClass]="{'bg-indigo-50 !border-indigo-600 !relative': storageObject && (container.selection.has(storageObject) || container.existingIds.includes(storageObject.id))}">
        <button
        [disabled]="(container.loadingFolder | async) || container.existingIds.includes(storageObject?.id)"
          class="size-6 rounded-full hover:bg-indigo-100 grid place-content-center invisible group-hover:visible group-[.selected]:visible peer-aria-expanded:visible"
          (click)="container.toggleFolder(storageObject)">
          <i class="fa-solid fa-chevron-right text-[0.75rem]" [ngClass]="storageObject.open ? 'fa-rotate-90' : ''"></i>
        </button>
        <ng-container *ngIf="!(container.loadingFolder | async) || container.selectedFolder?.id != storageObject.id">
          <i class="fa-regular text-[1rem] text-slate-600 fa-folder" *ngIf="!storageObject"></i>
        </ng-container>
        <ng-container *ngIf="(container.loadingFolder | async) && container.selectedFolder?.id == storageObject.id">
          <i class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin"></i>
        </ng-container>
        <span class="font-medium text-slate-600 truncate text-sm">{{ storageObject.name | titlecase }}</span>
        <div class="px-3 ml-auto" *ngIf="!storageObject">
          <div class="size-6 rounded-full hover:bg-indigo-100 grid place-content-center invisible group-hover:visible">
            <i class="fa-solid fa-chevron-right text-sm"></i>
          </div>
        </div>
        <div class="px-3 ml-auto pointer-events-none flex items-center" *ngIf="storageObject">
          <div class="size-6 rounded-full grid place-content-center text-indigo-600 bg-indigo-100"
            [ngClass]="container.selection.has(storageObject) || container.existingIds.includes(storageObject.id) ? 'visible': 'invisible'">
            <i class="fa-solid fa-check text-[0.9rem]"></i>
          </div>
        </div>
      </div>
    </div>

    <!-- Espacio para mostrar las sub-carpetas o documentos dentro una carpeta -->
    <div class="pl-5">
      <app-dossier-folder-data [storageObjects]="storageObject.storageObjects"
        *ngIf="storageObject.storageObjects && storageObject.open" [parentId]="storageObject.id" />
    </div>
  </ng-container>

  <!-- Empty state si no hay carpetas o documentos dentro del asunto -->
  <div class="col-span-full justify-center items-center flex flex-col gap-1 pointer-events-none text-slate-400 py-2"
    *ngIf="!storageObjects[0] && (!isLoading() || parentId && container.selectedFolder?.id != parentId)">
    <div class="bg-indigo-100 size-8 grid place-content-center rounded-full">
      <i class="fa-solid fa-folder-open text-xs"></i>
    </div>
    <span class="text-center font-medium text-sm">No encontramos carpetas en esta ubicación</span>
  </div>
</div>