import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainLayoutComponent } from './components/main-layout/main-layout.component';
import { AccessGuard } from './guards/access.guard';
import { AuthGuard } from './guards/auth.guard';
import { BlogGuard } from './guards/blog.guard';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AMPARO_ROUTES } from '../features/amparo/amparo.routes';

const routes: Routes = [
    {
        path: 'welcome',
        canActivate: [AuthGuard],
        loadComponent: () =>
            import('../features/main/pages/welcome/welcome.component').then(
                (c) => c.WelcomeComponent
            ),
    },
    {
        path: 'subscription-success',
        canActivate: [AuthGuard],
        loadComponent: () =>
            import(
                '../features/main/pages/subscribed/subscribed.component'
            ).then((c) => c.SubscribedComponent),
    },
    {
        path: 'blog-md',
        loadComponent: () =>
            import(
                '../features/library/components/blog-layout/blog-layout.component'
            ).then((m) => m.BlogLayoutComponent),
        canMatch: [BlogGuard],
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        '../features/library/pages/blog/blog.component'
                    ).then((c) => c.BlogComponent),
            },
            {
                path: 'post/:code',
                loadComponent: () =>
                    import(
                        '../features/library/pages/blog-post/blog-post.component'
                    ).then((c) => c.BlogPostComponent),
            },
        ],
    },
    {
        path: 'blog',
        loadComponent: () =>
            import(
                '../features/library/components/blog-layout/blog-layout.component'
            ).then((m) => m.BlogLayoutComponent),
        canMatch: [BlogGuard],
        children: [
            {
                path: '',
                loadComponent: () =>
                    import(
                        '../features/library/pages/blog/blog.component'
                    ).then((c) => c.BlogComponent),
            },
            {
                path: 'post/:code',
                loadComponent: () =>
                    import(
                        '../features/library/pages/blog-post/blog-post.component'
                    ).then((c) => c.BlogPostComponent),
            },
        ],
    },
    {
        path: '',
        canMatch: [BlogGuard],
        children: [
            {
                path: 'join',
                loadComponent: () =>
                    import(
                        '../features/security/pages/join-v2/join-v2.component'
                    ).then((c) => c.JoinV2Component),
            },
            {
                path: 'join/:refCode',
                loadComponent: () =>
                    import(
                        '../features/security/pages/join-v2/join-v2.component'
                    ).then((c) => c.JoinV2Component),
            },
            {
                path: 'thanks/:id',
                loadComponent: () =>
                    import(
                        '../features/main/pages/thanks/thanks.component'
                    ).then((c) => c.ThanksComponent),
            },
            {
                path: 'security',
                loadChildren: () =>
                    import('../features/security/security.module').then(
                        (m) => m.SecurityModule
                    ),
            },
            {
                path: '',
                component: MainLayoutComponent,
                children: [
                    {
                        path: 'main',
                        canActivate: [AuthGuard],
                        loadChildren: () =>
                            import('../features/main/main.module').then(
                                (m) => m.MainModule
                            ),
                    },
                    {
                        path: 'amparo',
                        children: AMPARO_ROUTES,
                    },
                    {
                        path: 'search',
                        canActivate: [AuthGuard],
                        loadChildren: () =>
                            import('../features/search/search.module').then(
                                (m) => m.SearchModule
                            ),
                    },
                    {
                        path: 'law-firm',
                        canActivate: [AuthGuard],
                        canActivateChild: [AccessGuard],
                        loadChildren: () =>
                            import('../features/law-firm/law-firm.module').then(
                                (m) => m.LawFirmModule
                            ),
                    },
                    {
                        path: 'settings',
                        canActivate: [AuthGuard],
                        loadChildren: () =>
                            import('../features/settings/settings.module').then(
                                (m) => m.SettingsModule
                            ),
                    },
                    {
                        path: 'updates',
                        canActivate: [AuthGuard],
                        loadChildren: () =>
                            import('../features/updates/updates.module').then(
                                (m) => m.UpdatesModule
                            ),
                    },
                    {
                        path: 'calendar',
                        canActivate: [AuthGuard],
                        loadChildren: () =>
                            import('../features/calendar/calendar.module').then(
                                (m) => m.CalendarMDModule
                            ),
                    },
                    {
                        path: 'editor',
                        canActivate: [AuthGuard],
                        loadChildren: () =>
                            import(
                                '../features/doc-editor/doc-editor.module'
                            ).then((m) => m.DocEditorModule),
                    },
                    {
                        path: '**',
                        pathMatch: 'full',
                        redirectTo: 'main',
                    },
                ],
            },
        ],
    },
    {
        path: '404',
        component: NotFoundComponent,
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: '',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
})
export class CoreRoutingModule {}
