<span *ngIf="showLabel" class="block text-sm font-medium mb-1 text-slate-500">
  {{ label }} <span *ngIf="requiredCheck" class="text-rose-500">*</span>
</span>
<div class="w-full flex flex-col items-center relative"
  [ngClass]="{ '!border-slate-200 !bg-slate-100 text-slate-400 !cursor-not-allowed' : this.ngControl.control?.disabled}">
  <div [ngClass]="{
              'border-rose-300': this.ngControl.control?.errors && this.ngControl.control?.touched,
              '!border-slate-200 !bg-slate-100 text-slate-400 !cursor-not-allowed': this.ngControl.control?.disabled
          }" [class]="checkTypeInput(objectConfig.type)">
    <div class="flex flex-auto flex-wrap gap-1">
      <div *ngFor="let tag of returnTags()"
        class="break-words flex items-center justify-center font-medium py-1 px-2 rounded-xl text-indigo-700 bg-indigo-100 border border-indigo-300"
        style="word-break: break-word;">
        <div class="text-xs">{{outPutTags(tag)}}</div>
        <div class="flex-auto flex-row-reverse">
          <div (click)="removeTag(tag)" class="cursor-pointer hover:text-indigo-400 p-1">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor"
              class="feather feather-x w-4 h-4">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
        </div>
      </div>
      <div class="flex-1">
        <!-- <input [ngClass]="{
          '!border-slate-200 !bg-slate-100 text-slate-400 !cursor-not-allowed' : this.ngControl.control?.disabled,
        }" autocomplete="new-password" (input)="validateInput()" (focus)="onInputChanges(this.inputValue)" #inputTag
          type="text"
          class="bg-transparent p-0 bg-white appearance-none border-0 outline-none h-full w-full text-gray-800 min-w-[3rem] focus:ring-0 truncate"
          [(ngModel)]="inputValue" [placeholder]="loading ? 'Cargando...' : label"
          (ngModelChange)="onInputChanges($event)" [disabled]="this.ngControl.control?.disabled || false"> -->
          <input [ngClass]="{
            '!border-slate-200 !bg-slate-100 text-slate-400 !cursor-not-allowed' : this.ngControl.control?.disabled,
          }" autocomplete="new-password" 
            #inputTag type="text"
            class="bg-transparent p-0 bg-white appearance-none border-0 outline-none h-full w-full text-gray-800 min-w-[3rem] focus:ring-0 truncate"
            [(ngModel)]="inputValue" [placeholder]="loading ? 'Cargando...' : label"
              [disabled]="this.ngControl.control?.disabled || false">
        <ul
          class="absolute left-0 bg-white shadow rounded mt-2 py-1 w-75 max-w-[400px] min-w-[200px] z-50 h-fit max-h-[200px] overflow-y-auto"
          *ngIf="filteredMentions.length > 0 && !loadSearching">
          <li
            class="px-3 py-1  hover:bg-slate-50 cursor-pointer min-h-[50px]  border-b-2 !border-b-slate-200 !h-[fit-content] flex items-center align-center"
            (click)="clickMention(mention)" *ngFor="let mention of filteredMentions; let i = index"
            [ngClass]="{'bg-slate-50 text-primary-100': selectedIndex === i}">
            <img class="pointer-events-none w-8 h-8 rounded-full mx-2" *ngIf="isInputUser"
              [src]="mention?.selfie ? mention?.selfie : '/assets/images/profile-placeholder.png'" width="32"
              height="32" alt="User" />
            {{ mention.label }}
          </li>
        </ul>
        <div *ngIf="loadSearching"
          class="absolute left-0 bg-white shadow rounded mt-1 py-1 w-75 max-w-[400px] z-50 h-fit max-h-[200px] overflow-y-auto">
          <div class="px-3 py-1 text-center text-gray-500">Buscando...</div>
        </div>
        <div *ngIf="!loadSearching && filteredMentions.length === 0 && inputValue.length > 0 && hasFocus"
          class="absolute left-0 bg-white shadow rounded mt-1 py-1 w-75 max-w-[400px] z-50 h-fit max-h-[200px] overflow-y-auto">
          <div class="px-3 py-1 text-center text-gray-500">No se encontraron resultados</div>
        </div>
      </div>
    </div>
  </div>
</div>