import { CommonModule } from '@angular/common';
import {
    AfterViewInit,
    Component,
    EventEmitter,
    HostListener,
    inject,
    Input,
    Output,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, map } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { DialogService } from 'src/app/core/services/dialog.service';
import { WebSocketService } from 'src/app/core/services/v2-socket.io.service';
import { ALERT_TOAST_DEFAULTS } from 'src/app/features/calendar/constants/alert-defaults.constants';
import { DocEditorComponent } from 'src/app/features/doc-editor/components/doc-editor/doc-editor.component';
import { StudioFiles } from 'src/app/features/law-firm/interfaces/studio-file.interface';
import { MenuModule } from 'src/app/shared/modules/menu.module';
import { loadingState } from 'src/app/shared/operators/loading-state.operator';
import { UisrApiServiceV2 } from 'src/app/shared/services/uisr-api.service-v2';
import Swal from 'sweetalert2';
import { AmparoIaFileAvailabilityComponent } from '../../../amparo-ia-file-availability/amparo-ia-file-availability.component';
import { PdfViewerModalComponent } from '../../../pdf-viewer-modal/pdf-viewer-modal.component';

@UntilDestroy()
@Component({
    selector: 'app-amparo-generated-file',
    standalone: true,
    imports: [CommonModule, AmparoIaFileAvailabilityComponent, MenuModule],
    templateUrl: './amparo-generated-file.component.html',
    host: {
        class: 'text-slate-800 mb-1',
    },
})
export class AmparoGeneratedFileComponent implements AfterViewInit {
    private readonly socketService = inject(WebSocketService);
    private readonly translate = inject(TranslateService);
    private readonly dialog = inject(DialogService);
    private readonly api = inject(UisrApiServiceV2);

    totalWidth: number = window.innerWidth;
    loading = new BehaviorSubject(false);
    doc: StudioFiles | null = null;
    messages: any = {};

    @Input({ required: true }) studio_file_id!: number;

    @Output() doc_ready = new EventEmitter<any>();
    @Output() copy_request = new EventEmitter<StudioFiles>();

    @HostListener('window:resize', []) updateVisiblePages() {
        this.totalWidth = window.innerWidth;
    }

    ngOnInit() {
        this.getMessages();
    }

    ngAfterViewInit() {
        this.getFile();
    }

    private getMessages() {
        this.messages = this.translate.instant([
            'FILE_NOT_AVAILABLE',
            'DELETE_FILE_QUESTION',
            'DELETE_ACTIVITY_QUESTION',
        ]);
    }

    /** Obtiene el archivo desde el estudio */
    private getFile() {
        this.api
            .get(RESOURCES.studioFiles, {
                studio_file_id: this.studio_file_id,
            })
            .pipe(loadingState(this.loading), untilDestroyed(this))
            .subscribe({
                next: (res: APIResponse<StudioFiles[]>) => {
                    this.doc = res.data[0];
                    this.doc_ready.emit({
                        ...this.doc,
                        type: 'studioFile',
                        id: this.studio_file_id,
                    });
                    this.listenForStatusChanges();
                },
            });
    }

    /** Obtiene la url pública del archivo */
    private getFileUrl() {
        return this.api
            .get(RESOURCES.studioFiles, {
                studio_file_id: this.studio_file_id,
            })
            .pipe(
                map((res: APIResponse<StudioFiles[]>) => res.data[0].public_url)
            );
    }

    /** Dependiendo del estado del run, se escucha por cambios de estado por el websocket  */
    private listenForStatusChanges() {
        if (
            this.doc?.last_vector_run?.status === 'in_progress' ||
            this.doc?.last_vector_run?.status === 'pending'
        ) {
            const run_id = this.doc.last_vector_run?.run_id;
            this.socketService.socket?.on(`assistants_update_${run_id}`, () => {
                this.getFile();
            });
        }
    }

    downloadOrPreview(action: 'preview' | 'download' | 'newTab' | null = null) {
        if (!this.doc?.public_url) {
            Swal.fire({
                ...ALERT_TOAST_DEFAULTS,
                text: this.messages.FILE_NOT_AVAILABLE,
                icon: 'error',
            });

            return;
        }
        if (!action) {
            action = this.doc.extension == 'pdf' ? 'preview' : 'newTab';
        }

        switch (action) {
            case 'preview':
                if (
                    this.doc.extension == 'docx' ||
                    this.doc.extension == 'doc'
                ) {
                    this.editDocx(true);
                } else {
                    this.dialog.openSidePanel(PdfViewerModalComponent, {
                        data: this.doc.public_url,
                    });
                }
                break;
            case 'newTab':
                const anchor = document.createElement('a');
                anchor.href = this.doc.public_url;
                anchor.target = '_blank';
                anchor.download = this.doc.name;
                anchor.click();
                break;

            default:
                break;
        }
    }

    /** Emite una solicitud para copiar el documento a otra area
     *  No está llamando al método directamente porque al llamar el componente de copiar, se produce una dependencia circular
     */
    copyTo() {
        if (this.doc) {
            this.copy_request.emit(this.doc);
        }
    }

    /** Editar un documento de word */
    editDocx(readOnly = false) {
        if (!this.doc) return;
        console.log(this.doc);

        this.dialog.open(DocEditorComponent, {
            hasBackdrop: false,
            panelClass: ['app-fullscreen-panel'],
            data: {
                title: this.removeExt(this.doc.name),
                documentId: this.doc?.studio_file_id,
                readOnly,
                loadUrl: this.getFileUrl.bind(this),
                saveFile: this.saveFileDocument.bind(this),
            },
        });
    }

    /** Método para guardar el documento, devuelve un observable para usarse dentro del modal en un callback */
    saveFileDocument(
        file: File,
        payload: { title: string }
    ) {
        if (!this.doc) return;
        this.doc.name = String(payload.title).trim();

        // data de la solicitud con file
        const formData = new FormData();
        formData.append('name', this.doc.name);
        formData.append('file', file);

        return this.api.patch(`${RESOURCES.studioFiles}/file/${this.doc?.studio_file_id}`, formData);
    }

    removeExt(input: string): string {
        if (!input) return '';

        const parts = input.split('.');
        parts.pop();

        return parts.join('.');
    }
}
