import { DateTime } from 'luxon';

// Extender las interfaces de luxon para incluir el métodos personalizados
declare module 'luxon' {
    /**
     * Metodos Staticos
     */
    namespace DateTime {
        /**
         * Crea un DateTime a partir de un objeto Date de JavaScript y establece la zona horaria
         * sin ajustar la hora local del objeto Date.
         * Ejemplo: 2024-10-17T00:00:00.000+09:00 -> 2024-10-17T00:00:00.000-04:00
         * @param date - El objeto Date de JavaScript.
         * @param zone - La zona horaria a establecer.
         * @returns Un objeto DateTime con la zona horaria establecida y la hora local sin cambios.
         */
        function fromRawJsDateZone(date: Date, zone: string): DateTime;

        function toRawJSDate(): Date;
    }

    /**
     * Metodos Instanciados
     */
    interface DateTime {
        /**
         * Convierte el objeto DateTime a un objeto Date de JavaScript "en bruto", sin aplicar
         * transformaciones de zona horaria.
         * Ejemplo: 2024-10-17T00:00:00.000+09:00 -> 2024-10-17T00:00:00.000-04:00
         */
        toRawJSDate(): Date;
    }
}

/////////////////////
////// Metodos

DateTime.prototype.toRawJSDate = function (): Date {
    return this.setZone(DateTime.local().zoneName || '', { keepLocalTime: true }).toJSDate();
};

DateTime.fromRawJsDateZone = function (date: Date, zone: string): DateTime {
    return DateTime.fromJSDate(date).setZone(zone, { keepLocalTime: true });
};
