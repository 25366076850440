<div class="bg-white text-slate-800 rounded-lg border border-slate-200 shadow-sm mb-1 flex flex-col"
  *ngIf="doc && !(loading | async)">
  <div class="flex min-w-0 gap-4 p-4 ">
    <div class="rounded w-24 h-28 bg-slate-50 border border-slate-200 flex flex-col px-3 py-2 cursor-pointer" (click)="editDocx()">
      <div class="flex items-center justify-center my-auto text-xl">
        📄⚖️
      </div>
    </div>
    <div class="flex flex-col min-w-0 grow">
      <div class="flex items-center justify-between gap-2">
        <span class="truncate text-left leading-5 text-slate-800 font-semibold flex items-center gap-4 text-xl">
          {{ doc.name.replace('.docx', '') }}
        </span>
      </div>
      <div class="text-sm text-slate-500">{{doc.created_at | date:"dd.MM.yyyy"}} • {{doc.extension}}</div>
      <div class="flex items-center gap-2 mt-8">
        <button (click)="editDocx()" class="flex items-center gap-2 px-2 py-1 border border-purple-500 rounded-lg text-slate-600 text-sm hover:shadow-md hover:text-slate-800">
          <i class="fa-regular fa-file-pen"></i> Editar
        </button>
        <button (click)="downloadOrPreview()" class="flex items-center gap-2 px-2 py-1 border border-slate-300 rounded-lg text-slate-600 text-sm hover:shadow-md hover:text-slate-800">
          <i class="fa-regular fa-download"></i> Descargar
        </button>
        <button (click)="copyTo()" class="flex items-center gap-2 px-2 py-1 border border-slate-300 rounded-lg text-slate-600 text-sm hover:shadow-md hover:text-slate-800" ngxTippy="Copiar a otra área">
          <i class="fa-regular fa-copy"></i> Copiar
        </button>
        <i class="fa-regular fa-circle-check text-emerald-600"></i>
      </div>
    </div>
  </div>
</div>

<div *ngIf="(loading | async)">
  <div class="border-2 border-slate-200 rounded-lg p-2 animate-pulse flex gap-2">
    <div class="w-32 h-36 rounded-lg bg-slate-200"></div>
    <div class="flex flex-col gap-1">
      <div class="w-40 h-6 rounded-md bg-slate-200"></div>
      <div class="w-20 h-6 rounded-md bg-slate-200"></div>
    </div>
  </div>
</div>
