<div
	class="@container z-[100] absolute shadow-lg overflow-hidden max-h-[calc(100svh-3.625rem)] sm:max-h-[calc(100svh-5.625rem)] transition-all"
	[ngClass]="{
		'w-full h-full right-0 bottom-0 rounded-none sm:border sm:border-slate-200 sm:right-8 sm:bottom-8 sm:rounded-xl sm:h-[80vh] sm:w-[70svw] xl:w-[60svw] 2xl:w-[55svw]': !assistantChatService.componentStates.close,
		'!w-0 !h-0 !right-8 !bottom-8 !rounded-full !border !border-slate-200': assistantChatService.componentStates.close,
		'sm:!w-full sm:!h-full sm:!right-0 sm:!bottom-0 !rounded-none !border-none !max-h-[calc(100svh-3.625rem)]': assistantChatService.componentStates.maximize
	}">
	<div class="relative w-full h-full">
		<div (click)="assistantChatService.open()"
			class="absolute w-full h-full top-0 left-0 bg-white z-[1] transition-all flex items-center justify-center cursor-pointer select-none"
			[ngClass]="assistantChatService.componentStates.close ? 'pointer-events-auto opacity-100' : 'pointer-events-none opacity-0'">
			<img class="size-10 rounded-full" src="/assets/images/amparo-isotipo.webp" alt="MiDespacho Chat Logo" />
		</div>
		<div class="absolute w-full h-full top-0 left-0">
			<div class="relative flex h-full bg-slate-100">

				<!-- Messages sidebar -->
				<div
					class="absolute top-0 bottom-0 left-0 h-full w-full @xl:w-auto @xl:static @xl:top-auto @xl:bottom-auto -mr-px transition-all"
					[ngClass]="{ '-translate-x-full pointer-events-none': !assistantChatService.componentStates.threadsMenu }">
					<div class="sticky bg-white overflow-hidden shrink-0 border-r border-slate-200 @xl:w-72 h-full">

						<div class="h-full flex flex-col">
							<!-- Header -->
							<div class="sticky top-0 z-10">
								<div class="flex items-center bg-white border-b border-slate-200 px-5 h-16">
									<div class="w-full flex items-center justify-between">

										<!-- Nombre Asistente -->
										<img class="h-12 rounded-full mr-2" src="/assets/images/amparo-logo.webp" height="32"
											alt="Amparo IA" />

										<div class="flex">
											<!-- botón de información -->
											<button id="assistant-tour-button" (click)="assistantChatService.showInfoContent()"
												class="group size-8 grid place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
												ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Ver Información'">
												<i class="fa-duotone fa-info-circle opacity-75 group-hover:opacity-100"
													style="font-size: 1rem"></i>
											</button>
											<!-- Ocultar sidebar de conversaciones -->
											<div class="flex">
												<button
													(click)="assistantChatService.componentStates.threadsMenu = !assistantChatService.componentStates.threadsMenu"
													class="group size-8 grid place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
													ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }"
													[ngxTippy]="'Ocultar Conversaciones'">
													<i class="fa-duotone fa-arrow-left-from-line group-hover:opacity-100"
														style="font-size: 1rem"></i>
												</button>
											</div>

										</div>
									</div>
								</div>
							</div>

							<app-chat-threads></app-chat-threads>

						</div>
					</div>
				</div>

				<!-- Messages body -->
				<div class="absolute top-0 bottom-0 w-full right-0 h-full flex flex-col overflow-hidden transition-all"
					[ngClass]="assistantChatService.componentStates.threadsMenu ? 'translate-x-full @xl:translate-x-0 @xl:w-[calc(100%-18rem)]' : 'w-full'">

					<!-- Header -->
					<div>
						<div
							class="flex items-center justify-between bg-slate-50 border-b border-slate-200 px-4 sm:px-6 md:px-5 h-16">

							<!-- Close button -->
							<button *ngIf="!assistantChatService.componentStates.threadsMenu"
								(click)="assistantChatService.componentStates.threadsMenu = !assistantChatService.componentStates.threadsMenu"
								class="w-8 h-8 min-w-[2rem] min-h-[2rem] mr-2 cursor-pointer grid place-content-center p-1 rounded bg-white border border-slate-200 hover:border-slate-300 shadow-sm"
								ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Mostrar Conversaciones'">
								<i class="fa-duotone fa-arrow-right-from-line opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
							</button>

							<p class="min-w-0 max-w-full font-semibold text-slate-800 inline-block truncate mr-auto" ngxTippy
								[tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="threadName">
								{{ threadName }}
							</p>

							<!-- Buttons on the right side -->
							<div class="flex">

								<!-- Thread docs -->
								<button id="assistant-document-list"
									*ngIf="assistantChatService.loadingStates?.deletingDocuments?.value || assistantChatService.loadingStates?.addingDocuments?.value || assistantChatService.documents[0] || tutorialService.isTutorialActive('assistant-tour')"
									[menuTriggerFor]="threadDocs" [tippyProps]="{ placement: 'top', hideOnClick: true }"
									[ngxTippy]="docsProcessing() ? null : 'Contexto de la Conversación'"
									class="relative grid size-8 shrink-0 place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm">
									<i *ngIf="!assistantChatService.loadingStates?.deletingDocuments?.value && !assistantChatService.loadingStates?.addingDocuments?.value"
										class="fa-duotone fa-file-lines opacity-75 hover:opacity-100" style="font-size: 1rem"></i>
									<i *ngIf="assistantChatService.loadingStates?.deletingDocuments?.value || assistantChatService.loadingStates?.addingDocuments?.value "
										class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin"
										style="font-size: 1rem"></i>
									<div
										*ngIf="!assistantChatService.loadingStates?.deletingDocuments?.value && !assistantChatService.loadingStates?.addingDocuments?.value && !docsProcessing()"
										class="absolute grid place-content-center size-5 text-[0.6rem] leading-5 font-medium text-white bg-indigo-500 border-2 border-white rounded-full -top-2 -right-2">
										{{ assistantChatService.documents.length }}
									</div>
									<div ngxTippy="Amparo IA está procesando los documentos seleccionados."
										class="absolute grid place-content-center size-5 font-medium text-white bg-slate-500 border-2 border-white rounded-full -top-2 -right-2 animate-spin"
										*ngIf="!assistantChatService.loadingStates?.deletingDocuments?.value && !assistantChatService.loadingStates?.addingDocuments?.value && docsProcessing()">
										<i class="fa-solid fa-spinner-third " style="font-size: 0.6rem"></i>
									</div>
								</button>
								<ng-template #threadDocs>
									<ul
										*ngIf="!assistantChatService.loadingStates?.deletingDocuments?.value && !assistantChatService.loadingStates?.addingDocuments?.value"
										class="mt-1 bg-pearl-100 border border-slate-200 rounded shadow-lg min-w-44 py-1.5 animate__animated animate__fadeIn animate__faster max-h-72 overflow-y-auto"
										cdkMenu>
										<li class="flex w-full mt-1 mb-2 px-3 justify-between items-center border-b border-slate-200 pb-1"
											*ngIf="canAddFiles()">
											<span class="text-sm">Contexto</span>
											<button *appValidateAccess="'asistente:documentos'" ngxTippy="Añadir Contexto"
												class="shrink-0 size-7 rounded grid place-content-center gap-1 text-slate-600 hover:text-slate-700 hover:bg-slate-300/60 hover:shadow-sm"
												(click)="viewDocs()"
												[disabled]="assistantChatService.loadingStates.sendingMessage.value || assistantChatService.loadingStates.activeRun.value">
												<i class="fa-regular fa-paperclip-vertical" style="font-size: 0.9rem;"></i>
											</button>
										</li>
										<li *ngFor="let doc of assistantChatService.documents; let i = index;" cdkMenuItem
											class="max-w-full xs:max-w-96">
											<div class="app-menu-option flex justify-between">
												<button class="truncate mr-2" (click)="viewDoc(doc)"
													ngxTippy="Ver síntesis generada por Amparo IA"
													[tippyProps]="{ hideOnClick: true, trigger: 'mouseenter' }">
													{{ (doc.heading || doc.name) | titlecase }}
												</button>
												<!-- Si el documento está en procesamiento, mostramos un loader -->
												<div *ngIf="doc.isProcessing" class="size-7 shrink-0 grid place-content-center ml-auto"
													ngxTippy="Amparo IA está generando la síntesis del documento">
													<i class="fa-solid fa-spinner-third animate-spin" style="font-size: 0.9rem;"></i>
												</div>
												<!-- Si no está en procesamiento, mostramos el botón de la lupa -->
												<button *ngIf="!doc.isProcessing" (click)="viewDoc(doc)"
													class="size-7 shrink-0 grid place-content-center ml-auto rounded text-slate-400 hover:text-slate-500 hover:bg-slate-300/60 hover:shadow-sm"
													[tippyProps]="{ placement: 'top', hideOnClick: true }"
													ngxTippy="Ver síntesis generada por Amparo IA">
													<i class="fa-solid fa-magnifying-glass" style="font-size: 0.9rem;"></i>
												</button>
												<!-- Eliminar de la conversación -->
												<button (click)="assistantChatService.removeDoc(doc)"
													class="size-7 shrink-0 grid place-content-center rounded text-rose-400 hover:text-rose-500 hover:bg-rose-300/60 hover:shadow-sm"
													[tippyProps]="{ placement: 'top', hideOnClick: true }"
													[ngxTippy]="'Eliminar de la Conversación'">
													<i class="fa-solid fa-xmark" style="font-size: 0.9rem"></i>
												</button>
											</div>
										</li>
									</ul>
								</ng-template>

								<!-- IA Model -->
								<button id="assistant-model-selector" [menuTriggerFor]="modelsList"
									[disabled]="assistantChatService.loadingStates.sendingMessage.value || assistantChatService.loadingStates.activeRun.value"
									class="group size-8 grid place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm" ngxTippy
									[tippyProps]="{ placement: 'top', hideOnClick: true }"
									[ngxTippy]="'Modelo de Inteligencia Artificial'">
									<i *ngIf="!assistantChatService.loadingStates?.loadingModels?.value"
										class="fa-duotone fa-microchip-ai opacity-75 group-hover:opacity-100"></i>
									<i *ngIf="assistantChatService.loadingStates?.loadingModels?.value"
										class="fa-solid text-[1rem] text-slate-600 fa-spinner-third animate-spin"></i>
								</button>
								<ng-template #modelsList>
									<ul *ngIf="!assistantChatService.loadingStates?.loadingModels?.value"
										class="uisr-dropdown-menu animate__animated animate__fadeIn animate__faster max-h-72 overflow-y-auto"
										cdkMenu>
										<li *ngFor="let iModel of assistantChatService.models; let i = index;" cdkMenuItem
											class="max-w-full xs:max-w-72">
											<a role="button" [ngClass]="{ 'text-slate-800' : assistantChatService.model != iModel.model_id }"
												class="app-menu-option justify-between group"
												(click)="assistantChatService.setModel(iModel.model_id)">
												<div class="flex flex-col min-w-0 items-start w-full">
													<div class="flex items-center w-full">
														<button [ngClass]="{ '!opacity-100' : assistantChatService.model == iModel.model_id }"
															class="opacity-25 group-hover:opacity-100 w-8 h-8 min-w-8 min-h-8 cursor-pointer grid place-content-center p-1 rounded mr-2">
															<i class="fa-solid fa-circle-check opacity-75 hover:opacity-100"
																style="font-size: 1rem;"></i>
														</button>
														<span class="truncate">
															{{ iModel.display_name }}
														</span>
													</div>
													<div class="text-xs text-slate-600">
														{{ iModel.description }}
													</div>
												</div>
											</a>
										</li>
									</ul>
								</ng-template>

								<!-- Minimize button -->
								<button
									*ngIf="assistantChatService.componentStates.maximize && assistantChatService.componentStates.canClose"
									(click)="assistantChatService.minimize()"
									class="hidden sm:grid size-8 place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
									ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Minimizar'">
									<i class="fa-duotone fa-arrows-minimize opacity-75 group-hover:opacity-100"
										style="font-size: 1rem"></i>
								</button>

								<!-- Maximize button -->
								<button
									*ngIf="!assistantChatService.componentStates.maximize && assistantChatService.componentStates.canClose"
									(click)="assistantChatService.maximize()"
									class="hidden group sm:grid size-8 place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
									ngxTippy [tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Maximizar'">
									<i class="fa-duotone fa-arrows-maximize opacity-75 group-hover:opacity-100"
										style="font-size: 1rem"></i>
								</button>

								<!-- botón de ayuda -->
								<button id="assistant-tour-button" (click)="startTour()"
									class="group size-8 grid place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
									[tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Ver Ayuda'">
									<i class="fa-duotone fa-question-circle opacity-75 group-hover:opacity-100"
										style="font-size: 1rem"></i>
								</button>

								<!-- Close button -->
								<button *ngIf="assistantChatService.componentStates.canClose" (click)="assistantChatService.close()"
									class="group size-8 grid place-content-center rounded hover:bg-slate-300/60 hover:shadow-sm"
									[tippyProps]="{ placement: 'top', hideOnClick: true }" [ngxTippy]="'Cerrar'">
									<i class="fa-duotone fa-xmark opacity-75 group-hover:opacity-100" style="font-size: 1rem"></i>
								</button>

							</div>
						</div>
					</div>

					<!-- Thread -->
					<app-chat-thread></app-chat-thread>

				</div>
			</div>
		</div>
	</div>
</div>