import { TitleCasePipe } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  Optional,
  Self,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, fromEvent, lastValueFrom, Subject, switchMap, tap } from 'rxjs';
import { RESOURCES } from 'src/app/core/constants/resource-service.constants';
import { APIResponse } from 'src/app/core/interfaces/api.interface';
import { FormControlConfig } from 'src/app/core/interfaces/form-control-config.interface';
import { UserData } from 'src/app/core/models/user-data';
import { UserDataFull } from 'src/app/core/reducer/user-data/user-data.selector';
import { MemberInfo } from 'src/app/features/law-firm/interfaces/activity.interfaces';
import { Client } from 'src/app/features/law-firm/interfaces/info-dossier.interfaces';
import {
  MemberWorkSpace,
  TeamMember,
} from 'src/app/features/law-firm/interfaces/template.interfaces';
import { UisrApiServiceV2 } from '../../services/uisr-api.service-v2';

@UntilDestroy()
@Component({
  selector: 'app-input-tag-name-member',
  templateUrl: './input-tag-name-member.component.html',
})
export class InputTagNameMemberComponent
  implements ControlValueAccessor, AfterViewInit, OnChanges {
  private isMouseDown = false;
  public hasFocus = false;
  private isMouseMove = false;
  public loading: boolean = false;
  public loadSearching: boolean = false;
  @HostListener('mousedown')
  onMouseDown(event: MouseEvent): void {
    this.isMouseDown = true;
    // Manejar el evento cuando comienza el clic (mousedown)
  }

  @HostListener('mouseup')
  onMouseUp(event: MouseEvent): void {
    this.isMouseDown = false;
    if (!this.hasFocus) {
      this.onFocusOut();
    }
    // Manejar el evento cuando se levanta el dedo del mouse (mouseup)
  }

  @HostListener('mousemove', ['$event'])
  handleMousemove(event: any) {
    if (this.isMouseDown) {
      // Manejar el evento cuando se mueve el mouse (mousemove)
      this.isMouseMove = true;
    } else {
      this.isMouseMove = false;
    }
  }

  @HostListener('focusin')
  onFocusIn() {
    this.hasFocus = true;
  }

  @HostListener('focusout')
  onFocusOut() {
    this.hasFocus = false;
    setTimeout(() => {
      if (!this.isMouseDown && !this.isMouseMove) {
        this.filteredMentions = [];
        if (this.inputValue.length > 0 && this.enterTagMenu) {
          this.clickMention({
            label: this.inputValue,
          });
        }
        if (this.ngControl['name'] == 'casosLigados') {
          this.getCases();
        }
      }
    }, 200);
  }
  @HostListener('document:keydown', ['$event'])
  handleKeyboardEven(event: KeyboardEvent) {
    if (!this.hasFocus) {
      event.preventDefault();
    }
  }

  @Input() tagsExternal: any[] = [];
  @Input() showClientsOnly = false;
  @Input() showLabel: boolean = true;
  userDossier: any[] | Client[] | TeamMember[] | MemberWorkSpace[] = [];
  isDarkTheme: boolean = false;
  allowMentions: boolean = true; // Puedes establecer esto en 'false' inicialmente si lo prefieres
  @Input() inputClass: string | null = null;
  @Input() NotIdDossier!: number;
  @Input() objectConfig: FormControlConfig = {
    type: 'text',
    label: '',
    name: '',
  };
  filteredMentions: any[] = [];
  get label(): string {
    return this.objectConfig?.label || 'Listar miembros';
  }
  tags: string[] = [];
  tagsUsers: any[] = [];
  inputValue: string = '';

  // Estas funciones serán reemplazadas por Angular
  onTouched: any = () => { };
  onChange: any = () => { };
  resources = RESOURCES;
  isInputUser: boolean = false;
  enterTagMenu: boolean = true;
  requiredCheck: boolean = false;
  private inputSubject = new Subject<string>();
  @ViewChild('inputTag') inputTag!: ElementRef;
  userData?: UserData;
  constructor(
    @Optional() @Self() public ngControl: NgControl,
    private store: Store,
    private apiService: UisrApiServiceV2,
    public toast: ToastrService,
    private titleCasePipe: TitleCasePipe
  ) {
    if (this.ngControl != null) {
      // Setting the value accessor directly (instead of using
      // the providers) to avoid running into a circular import.
      this.ngControl.valueAccessor = this;
    }
    this.store
      .pipe(select(UserDataFull), untilDestroyed(this))
      .subscribe((data) => {
        this.userData = data;
        this.isDarkTheme = data.id_themes_preference == 2;
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes['tagsExternal'] &&
      changes['tagsExternal'].currentValue.length > 0
    ) {
      this.userDossier = changes['tagsExternal'].currentValue;
    }
  }

  ngAfterViewInit(): void {
    if (this.objectConfig.validators) {
      this.requiredCheck =
        this.objectConfig.validators?.filter((el) => el.type == 'required')
          .length > 0
          ? true
          : false;
    }
    if (this.ngControl.control?.disabled) return;

    if (
      this.ngControl['name'] == 'actorQuejoso' ||
      this.ngControl['name'] == 'demandadoAutoridadResponsable'
    ) {
      this.loading = true;
      this.isInputUser = true;
      this.getCliente(false);
    } else if (this.ngControl['name'] == 'equipoEncargado') {
      this.loading = true;
      this.enterTagMenu = false;
      this.isInputUser = true;
      this.getMemberWorkSpace();
    } else if (this.ngControl['name'] == 'clientes') {
      this.loading = true;
      this.enterTagMenu = false;
      this.isInputUser = true;
      this.getCliente();
    } else if (this.ngControl['name'] == 'casosLigados') {
      this.loading = true;
      this.getCases(true);
      this.inputSubject.pipe(
        tap(() => this.loadSearching = true), // Activar el indicador de búsqueda
        debounceTime(300),
        distinctUntilChanged(),
        untilDestroyed(this),
        switchMap(inputValue => {
          this.userDossier = [];
          let filter = {
            fkIdWorkspace: this.userData?.id_workspace_member || this.userData?.idWorkspace,
            pageNumber: 1,
            pageSize: 3, 
            name: inputValue,
          };
          return this.apiService.get(this.resources.getCases, filter);
        })
      ).subscribe({
        next: (res: APIResponse<any>) => {
          this.loadSearching = false; // Desactivar el indicador de búsqueda
          if (res.success) {
            this.userDossier = res.data.map((dossier: any) => {
              return {
                id: dossier.idDossier,
                label: dossier.name,
              };
            });
            this.onInputChanges(this.inputValue);
          }
        },
        error: () => {
          this.loadSearching = false; // Desactivar el indicador de búsqueda en caso de error
        }
      });
    }
    const inputEvent$ = fromEvent(this.inputTag.nativeElement, 'input');
    const focusEvent$ = fromEvent(this.inputTag.nativeElement, 'focus');

    let casosLigados$ = inputEvent$;

    if (!this.ngControl && this.ngControl['name'] == 'casosLigados') {
      casosLigados$ = inputEvent$.pipe(
        debounceTime(300)
      );
    }

    casosLigados$.subscribe((event: any) => {
      this.handleInputChange(event)
    });
    focusEvent$.subscribe(() => {
      this.handleInputChange({ target: { value: this.inputValue } });
    });
  }

  getCases(disable: boolean = false) { 
    let filter = {
      fkIdWorkspace:
        this.userData?.id_workspace_member || this.userData?.idWorkspace,
      pageNumber: 1,
      pageSize: 3, 
    };
    if (disable) {
      this.ngControl.control?.disable();
    }
    this.apiService.get(this.resources.getCases, filter).subscribe({
      next: (res: APIResponse<any>) => {
        if (res.success) {
          this.userDossier = res.data.map((dossier: any) => {
            return {
              id: dossier.idDossier,
              label: dossier.name,
            };
          });
          this.loading = false;
          if (this.userDossier.length == 0) {
            if (this.ngControl['name'] == 'casosLigados') {
              return;
            }
          }
          if (disable) {
            this.ngControl.control?.enable();
          }
        }
      },
    });
  }

  getCliente(bolClient: boolean = true) {
    let filter: any = {
      idWorkspace:
        this.userData?.id_workspace_member || this.userData?.idWorkspace,
    };

    if (bolClient) {
      filter.idStatus = 1;
      filter.bolClient = bolClient;
    }
    this.ngControl.control?.disable();
    this.apiService.get(this.resources.clients, filter).subscribe({
      next: (res: APIResponse<any>) => {
        if (res.success) {
          this.userDossier = res.data
            .map((client: Client) => {
              const label = this.titleCasePipe.transform(
                client.moralName
                  ? client.moralName
                  : client.lastName
                    ? `${client.names} ${client.lastName}`
                    : client.names
              );

              return {
                ...client,
                id: client.idClientDossier,
                label: label,
              };
            })
            .filter((member: any) => member.label);
          if (this.userDossier.length == 0) {
            if (this.ngControl['name'] == 'clientes') {
              this.loading = false;
              return;
            }
          }
          this.checkClienteMember();
        }
      },
    });
  }

  getMemberWorkSpace() {
    let filter = {
      bolDelete: false,
      fkIdWorkSpace:
        this.userData?.id_workspace_member || this.userData?.idWorkspace,
    };
    this.ngControl.control?.disable();
    this.apiService.get(this.resources.members, filter).subscribe({
      next: (res: APIResponse<any>) => {
        if (res.success) {
          this.userDossier = res.data
            .map((client: MemberInfo) => {
              const label = this.titleCasePipe.transform(client.fullName);

              return {
                ...client,
                label: label,
              };
            })
            .filter((member: any) => member.label);

          if (this.ngControl['name'] == 'equipoEncargado') {
            this.loading = false;
          }

          if (this.userDossier.length == 0) {
            return;
          }
          this.checkClienteMember();
        }
      },
    });
  }

  writeValue(val: any): void {
    if (!this.isInputUser) {
      this.tags = val || [];
    } else {
      this.tagsUsers = val || [];
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  removeTag(tag: any): void {
    if (this.isInputUser) {
      const indexUser = this.tagsUsers.indexOf(tag);
      if (indexUser >= 0) {
        this.tagsUsers.splice(indexUser, 1);
      }
      this.selectedIndex = 0;

      this.writeValue(this.tagsUsers);
      this.onChange(this.tagsUsers);
      return;
    } else {
      const index = this.tags.indexOf(tag);
      if (index >= 0) {
        this.tags.splice(index, 1);
      }
      this.selectedIndex = 0;
      this.writeValue(this.tags);
      this.onChange(this.tags);
      this.onTouched();
      return;
    }
  }

  checkTypeInput(type: string) {
    if (this.inputClass) {
      return this.inputClass;
    }
    switch (type) {
      case 'text':
      case 'password':
      case 'email':
        if (this.objectConfig.formatCleave == 'cardNumber') {
          return 'form-input w-11/12';
        } else {
          return 'form-input w-full';
        }
      case 'checkbox':
        return 'form-checkbox';
      case 'radio':
        return 'form-radio';
      default:
        return 'form-input w-full';
    }
  }
  checkAtCharacter(event: KeyboardEvent): void {
    const input = event.target as HTMLInputElement;
    // Si el usuario intenta ingresar '@' y no es el primer carácter, lo impedimos.
    if (event.key === '@' && input.value.length !== 0) {
      event.preventDefault();
    }
  }

  validateInput() {
    if (this.ngControl['name'] == 'casosLigados') {
      setTimeout(() => {
        this.inputSubject.next(this.inputValue);
      }, 100);
    }
  }

  private async handleInputChange(event: any) {

    const value = event.target.value.toLowerCase();

    if (this.ngControl['name'] == 'casosLigados') {
      let filter = {
        fkIdWorkspace: this.userData?.id_workspace_member || this.userData?.idWorkspace,
        pageNumber: 1,
        pageSize: 5,
        name: this.inputValue,
      };

      try {
        const res: APIResponse<any> = await lastValueFrom(this.apiService.get(this.resources.getDossiers, filter));
        if (res.success) {
          this.userDossier = res.data.map((dossier: any) => {
            return {
              id: dossier.idDossier,
              label: dossier.name,
            };
          });
        }
      } catch (error) {
        console.error('Error fetching dossiers:', error);
      }
    }
    setTimeout(() => {
      // Define an array of possible mentions
      const mentions = this.userDossier;

      // Filter the mentions array to only include mentions that contain the entered text
      if (this.isInputUser) {
        const filteredMentions = value
          ? mentions.filter((mention) =>
            mention.label.toLowerCase().includes(value)
          )
          : mentions;
        filteredMentions.sort((a, b) => a.label.localeCompare(b.label));
        // Update the component state with the filtered mentions
        let getTags = this.tagsUsers
          .filter((tag) => typeof tag === 'object')
          .map((tag) => tag.id || tag.idMembersWorkspace);
        this.filteredMentions = filteredMentions.filter(
          (mention) =>
            !getTags.includes(mention.id || mention.idMembersWorkspace)
        );


      } else {
        const filteredMentions = mentions.filter((mention) =>
          mention.label.toLowerCase().includes(value)
        );
        this.filteredMentions = filteredMentions
          .filter(
            (mention) => !this.tags.includes(mention.label)
          );
      }
      if (this.ngControl['name'] == 'actorQuejoso'
        || this.ngControl['name'] == 'demandadoAutoridadResponsable' ||
        this.ngControl['name'] == 'clientes') {
        this.filteredMentions = this.filteredMentions
          .filter((tag: any) => tag?.bolClient == true && tag?.idStatus == 1)
      }
    }, 0);
  }

  public onInputChanges(event: any) {
    console.log(event);
    setTimeout(() => {
      if (!this.hasFocus) {
        event.preventDefault();
      }

      //Get the current value of the input field
      const value = event.toLowerCase();

      // Define an array of possible mentions
      const mentions = this.userDossier;
      // Filter the mentions array to only include mentions that contain the entered text
      if (this.isInputUser) {
        const filteredMentions = value
          ? mentions.filter((mention) =>
            mention.label.toLowerCase().includes(value)
          )
          : mentions;
        filteredMentions.sort((a, b) => a.label.localeCompare(b.label));
        // Update the component state with the filtered mentions
        let getTags = this.tagsUsers
          .filter((tag) => typeof tag === 'object')
          .map((tag) => tag.id || tag.idMembersWorkspace);
        this.filteredMentions = filteredMentions.filter(
          (mention) =>
            !getTags.includes(mention.id || mention.idMembersWorkspace)
        );


      } else {
        const filteredMentions = mentions.filter((mention) =>
          mention.label.toLowerCase().includes(value)
        );
        this.filteredMentions = filteredMentions
          .filter(
            (mention) => !this.tags.includes(mention.label)
          );
      }
      if (this.ngControl['name'] == 'actorQuejoso'
        || this.ngControl['name'] == 'demandadoAutoridadResponsable' ||
        this.ngControl['name'] == 'clientes') {
        this.filteredMentions = this.filteredMentions
          .filter((tag: any) => tag?.bolClient == true && tag?.idStatus == 1)
      }
    }, 0);
  }

  public selectedIndex = 0;

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (!this.hasFocus && this.filteredMentions.length > 0) {
      event.preventDefault();
    }

    if (event.key === 'ArrowDown') {
      // Move the selection down
      this.selectedIndex = Math.min(
        this.selectedIndex + 1,
        this.filteredMentions.length - 1
      );
    } else if (event.key === 'ArrowUp') {
      // Move the selection up
      this.selectedIndex = Math.max(this.selectedIndex - 1, 0);
    } else if (event.key === 'Enter') {
      // Select the currently highlighted mention

      if (
        (this.filteredMentions.length == 0 && this.inputValue == '') ||
        (this.filteredMentions.length == 0 &&
          !this.enterTagMenu &&
          this.inputValue != '') ||
        !this.hasFocus
      ) {
        return;
      } else if (this.isInputUser) {
        if (this.filteredMentions.length > 0) {
          const selectedMention =
            this.filteredMentions[this.selectedIndex].label;
          this.tagsUsers = [
            ...this.tagsUsers,
            this.filteredMentions[this.selectedIndex],
          ];
          this.tags = [...this.tags, selectedMention];
          this.writeValue(this.tagsUsers);
          this.onChange(this.tagsUsers);
          this.onTouched();
        } else if (this.filteredMentions.length == 0 && this.inputValue != '') {
          const selectedMention = this.inputValue;
          this.tagsUsers = [
            ...this.tagsUsers,
            {
              label: selectedMention,
            },
          ];
          this.writeValue(this.tagsUsers);
          this.onChange(this.tagsUsers);
          this.onTouched();
        }
      } else {
        if (this.filteredMentions.length > 0) {
          const selectedMention =
            this.filteredMentions[this.selectedIndex].label;
          this.tags = [...this.tags, selectedMention];
          this.writeValue(this.tags);
          this.onChange(this.tags);
          this.onTouched();
        } else {
          const selectedMention = this.inputValue;
          this.tags = [...this.tags, selectedMention];
          this.writeValue(this.tags);
          this.onChange(this.tags);
          this.onTouched();
        }
      }
      this.selectedIndex = 0;
      this.filteredMentions = [];
      this.inputValue = '';
    }
  }
  clickMention(mention: any) {
    if (this.isInputUser) {
      if (typeof mention === 'object') {
        this.tagsUsers = [...this.tagsUsers, mention];
      } else {
        this.tagsUsers = [...this.tagsUsers, mention.label];
      }
      this.writeValue(this.tagsUsers);
      this.onChange(this.tagsUsers);
      this.onTouched();
    } else {
      this.tags = [...this.tags, mention.label];
      this.writeValue(this.tags);
      this.onChange(this.tags);
      this.onTouched();
    }
    this.filteredMentions = [];
    this.inputValue = '';
  }

  returnTags() {
    if (this.isInputUser) {
      return this.tagsUsers;
    }
    if (typeof this.tags === 'string' || !Array.isArray(this.tags)) {
      this.tags = [this.tags];
    }

    return this.tags;
  }

  outPutTags(item: any) {
    if (this.isInputUser) {
      if (typeof item === 'object') {
        return item.label;
      } else {
        return item;
      }
    }
    return item;
  }

  async checkClienteMember() {
    if (this.tags.length > 0) {
      let clients = await this.processTags();
      if (
        this.ngControl['name'] == 'actorQuejoso' ||
        this.ngControl['name'] == 'demandadoAutoridadResponsable' ||
        this.ngControl['name'] == 'clientes'
      ) {
        this.tagsUsers = this.tags
          .map((tag: any) => {
            return clients.find(
              (user: any) => user.idClientDossier == tag.idClientDossier
            );
          });
        this.ngControl.control?.enable();
        this.loading = false;
      }
      if (this.ngControl['name'] == 'equipoEncargado') {
        this.tagsUsers = this.tags
          .map((tag: any) => {
            return clients.find(
              (user: any) => user.idMembersWorkspace == tag.idMembersWorkspace
            );
          })
          .filter((tag: any) => tag?.bolDelete == false);
      }
      this.tags = [];
    } else {
      this.ngControl.control?.enable();
      this.loading = false;
    }
  }

  async processTags(): Promise<any> {
    let filter: any = {
      idWorkspace:
        this.userData?.id_workspace_member || this.userData?.idWorkspace,
    };
    let resources = '';
    if (this.ngControl['name'] == 'clientes') {
      filter.idStatus = 1;
      filter.bolClient = true;
      resources = this.resources.clients;
    } else if (this.ngControl['name'] == 'equipoEncargado') {
      filter.bolDelete = false;
      filter.fkIdWorkSpace = filter.idWorkspace;
      resources = this.resources.members;
    } else if (
      this.ngControl['name'] == 'actorQuejoso' ||
      this.ngControl['name'] == 'demandadoAutoridadResponsable'
    ) {
      resources = this.resources.clients;
    }

    if (resources == '') return Promise.resolve([]);

    this.ngControl.control?.disable();
    let getData = await lastValueFrom(
      this.apiService.get(resources, filter)
    ).finally(() => {
      this.ngControl.control?.enable();
    });
    return getData.data
      .map((client: any) => {
        let label =
          client.moralName ||
          client.fullName ||
          `${client.names} ${client.lastName || ''}`;
        label = this.titleCasePipe.transform(label);

        if (
          this.ngControl['name'] == 'actorQuejoso' ||
          this.ngControl['name'] == 'demandadoAutoridadResponsable' ||
          this.ngControl['name'] == 'clientes'
        ) {
          return {
            ...client,
            id: client.idClientDossier,
            label: label,
          };
        } else if (this.ngControl['name'] == 'equipoEncargado') {
          return {
            ...client,
            label: label,
          };
        }
        return client;
      })
      .filter((member: any) => member.label);
  }
}
