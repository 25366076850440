import { CommonModule } from '@angular/common';
import { Component, inject, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { NgxTippyModule } from 'ngx-tippy-wrapper';
import { StorageObject } from 'src/app/features/law-firm/interfaces/storage-object.interface';
import { DossierFolderSelectorComponent } from '../dossier-folder-selector.component';

@Component({
    selector: 'app-dossier-folder-data',
    standalone: true,
    imports: [CommonModule, TranslateModule, NgxTippyModule],
    templateUrl: './dossier-folder-data.component.html',
    host: {
        class: 'contents',
    },
})
export class DossierFolderDataComponent {
    container = inject(DossierFolderSelectorComponent);
    @Input({ required: true }) storageObjects: StorageObject[] = [];
    @Input() parentId?: number;

    trackByFn(index: any, item: any) {
        return index;
    }

    isLoading() {
        return (
            this.container.loading.value || this.container.loadingFolder.value
        );
    }
}
