import { Route } from '@angular/router';

export const AMPARO_ROUTES: Route[] = [
    {
        path: '',
        loadComponent: () =>
            import('./amparo-home/amparo-home.component').then(
                (m) => m.AmparoHomeComponent
            ),
    },
    {
        path: ':id',
        loadComponent: () =>
            import('./amparo-home/amparo-home.component').then(
                (m) => m.AmparoHomeComponent
            ),
    },
];
